import React, { useState, useEffect, Fragment } from 'react';

import "../assets/styles/index.css";
import he from '../Services/I18n/he';
import ar from '../Services/I18n/ar';
import Select from 'react-select';
import ReactLoading from 'react-loading';

const languages = [
    {name: 'עברית', value: 'he'},
    {name: 'العربيه', value: 'ar'}
];

const israelCities = require('../israel-cities.json');
const israelCitiesArabic = require('../israel-cities-arabic.json');

const Contact = () => {

    const [fields, setFields] = useState([]);
    const [locale, setLocale] = useState(he);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [cityList, setCityList] = useState([]);
    const [cityVal, setCityVal] = useState({});
    const [isHebrew, setIsHebrew] = useState(true);
    const [cityIndex, setCityIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (e) => {

        let isHebrew = e.target.value === 'he';
        setIsHebrew(isHebrew);
        setLocale(isHebrew ? he : ar);
        changeCityList();

        resetValidations();
    }

    useEffect(() => {

        const fields = [];

        fields.push({id: 'firstName', title: locale.firstNameLabel, placeholder: locale.firstNamePlaceHolder});
        fields.push({id: 'lastName', title: locale.lastNameLabel, placeholder: locale.lastNamePlaceHolder});
        fields.push({id: 'email', title: locale.emailLabel, placeholder: locale.emailPlaceHolder});
        fields.push({id: 'phone', title: locale.phoneLabel, placeholder: locale.phonePlaceHolder});

        setFields(fields);
        changeCityList();
        
    }, [locale]);

    const changeCityList = () => {
        
        const cityList = [];
        
        if(isHebrew){
            for(let i = 0; i < israelCities.length; i++){
                cityList.push({value: israelCities[i].name, label: israelCities[i].name});
            }    
        } else {
            for(let i = 0; i < israelCitiesArabic.length; i++){
                cityList.push({value: israelCitiesArabic[i].name.trim(), label: israelCitiesArabic[i].name.trim()});
            }    
        }
        
        setCityList(cityList);
        setCityVal(cityList[0]);
    }

    const getFields = () => {
        let firstName = document.getElementById("firstName").value;
        let lastName = document.getElementById("lastName").value;
        let email = document.getElementById("email").value;
        let phone = document.getElementById("phone").value;

        return {firstName, lastName, email, phone};
    }

    const resetValidations = () => {
        setValidationMessage("firstName", "");
        setValidationMessage("lastName", "");
        setValidationMessage("email", "");
        setValidationMessage("phone", "");
    }

    const setValidationMessage = (id, val) => {
        document.getElementById("validation_" + id).innerHTML = val;
    }

    const IsValidEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const IsValidPhone = (phone) => {
        let ph = "";

        for(let i = 0; i < phone.length; i++){
            if(phone.charAt(i) >= '0' && phone.charAt(i) <= '9'){
                ph += phone.charAt(i);
            }
        }

        return ph.length === 10 && ph.substr(0, 2) === '05';
    }

    const getCityIdentifier = () => {
        
        try{
            if(cityIndex === -1){
                return "לא ידוע"
            } else {
                return isHebrew ? israelCities[cityIndex].name.trim() : israelCitiesArabic[cityIndex].name.trim();
            }
        }catch(er){
            console.error(er);
            return "לא ידוע"
        }
    }

    const saveForm = async () => {

        let requiredText = locale.requiredField;
        let {firstName, lastName, email, phone} = getFields();
        resetValidations();
        setSuccessMessage('');
        setErrorMessage('');
        setIsLoading(true);

        if(firstName.length === 0) {
            setValidationMessage("firstName", requiredText);
        } else if(lastName.length === 0) {
            setValidationMessage("lastName", requiredText);
        }
         else if (email.length === 0) {
            setValidationMessage("email", requiredText);
        }
        else if (phone.length === 0) {
            setValidationMessage("phone", requiredText);
        } else if(!IsValidEmail(email)) {
            setValidationMessage("email", locale.invalidEmail);
        } else if (!IsValidPhone(phone)) {
            setValidationMessage("phone", locale.invalidPhone);
        } else {

            let vehicle_ddl = document.getElementById("vehicleType");
            let gender_ddl = document.getElementById("gender");
            //let city_ddl = document.getElementById("city");

            let vehicle = vehicle_ddl.options[vehicle_ddl.selectedIndex].text;
            let vehicleTypeId = vehicle_ddl.value;

            let gender = gender_ddl.options[gender_ddl.selectedIndex].text;
            let genderId = gender_ddl.value;

            let city = cityVal.value;

            let userDetails = {
                firstName,
                lastName,
                email,
                phone,
                city,
                vehicle,
                vehicleTypeId,
                gender,
                genderId,
                cityIdentifier: getCityIdentifier()
            };

            let url = "https://safe-shelf-48405.herokuapp.com/api/email";
            //let url = "http://localhost:5000/api/email";

            let res = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({userDetails: userDetails})
            });

            if(res.status === 200){
                let response = await res.json();
                setSuccessMessage(locale.successMessage);

                setTimeout(() => {
                    window.location.reload();
                }, 1500);
            } else {
                setErrorMessage(locale.errorMessage);
            }
        }

        setIsLoading(false);
    }

    const updateCity = (val) => {

        let index = getIndexOfCity(val.value);
        setCityVal(val);
        setCityIndex(index);
    }

    const getIndexOfCity = (val) => {

        if(isHebrew){
            for(let i = 0; i < israelCities.length; i++){
                if(israelCities[i].name.trim() === val){
                    return i;
                }
            }    
        } else {
            for(let i = 0; i < israelCitiesArabic.length; i++){
                if(israelCitiesArabic[i].name.trim() === val){
                    return i;
                }
            }    
        }

        return -1;
    }


    return (
        <div className="container-contact100">
		<div className="wrap-contact100 custom-bg-img">
			<form className="contact100-form validate-form" style={{direction: "rtl"}}>

                <div style={{textAlign: 'center'}} className="w-100">
                    <img src={require('../assets/images/jpej-file-1.jpg')} alt="" style={{width: 350}} />
                </div>

				<span className="contact100-form-title">
                {locale.contactTitle}
				</span>

                <label className="label-input100">{locale.changeLanguage}</label>
                <select onChange={handleChange} className="form-control w-50">
                    {languages.map( (val, ind) => 
                    <option key={ind} value={val.value}>{val.name}</option>
                    )}
                </select>
                    
                {
                    fields.map((val, ind) =>
                        <Fragment key={ind}>
                            <label className="label-input100" htmlFor={val.id}>{val.title} *</label>
                            <div className="wrap-input100 validate-input">
                                <input id={val.id} className="input100" type="text" name={val.id} placeholder={val.placeholder} />
                                <span className="focus-input100"></span>
                                <span style={{color: "red"}} id={"validation_" + val.id}></span>    
                            </div>
                        </Fragment>
                    )
                }

                <label className="label-input100" htmlFor="vehicleType">{locale.vehicleType} *</label>
                <div className="wrap-input100 validate-input">
                    <select id="vehicleType" className="input100 form-control">
                        {locale.vehicleOptions.map( (val, ind) => 
                            <option key={ind} value={val.val}>{val.name}</option>
                        )}
                    </select>
                    <span style={{color: "red"}} id={"validation_vehicleType"}></span>    
                </div>

                <label className="label-input100" htmlFor="city">{locale.cityLabel} *</label>
                <div className="wrap-input100 validate-input">
                    {/* <select id="city" className="input100 form-control city-selector">
                        {cityList.map( (val, ind) => 
                            <option key={ind} value={val}>{val}</option>
                        )}
                    </select> */}

                    <Select
                        placeholder={locale.cityPlaceHolder}
                        value={cityVal}
                        options={cityList}
                        onChange={(val) => updateCity(val)}
                    />

                    <span style={{color: "red"}} id={"validation_city"}></span>    
                </div>

                <label className="label-input100" htmlFor="gender">{locale.gender} *</label>
                <div className="wrap-input100 validate-input">
                    <select id="gender" className="input100 form-control">
                        {locale.genderOptions.map( (val, ind) => 
                            <option key={ind} value={val.val}>{val.name}</option>
                        )}
                    </select>
                    <span style={{color: "red"}} id={"validation_gender"}></span>    
                </div>

                <div className="container-contact100-form-btn">
                    {
                        isLoading ? 
                        <div>
                            <ReactLoading type="spinningBubbles" color="black" width={50} height={50} />
                        </div>
                        :
                        <button className="contact100-form-btn" type="button" onClick={saveForm}>
                        {locale.submitContactButtonText}
                        </button>
                    }
				</div>


                <span style={{color: 'green', marginTop: 20}}>{successMessage}</span>
                <span style={{color: 'red', marginTop: 20}}>{errorMessage}</span>

			</form>

            {/*
			<div className="contact100-more flex-col-c-m"
                style={{backgroundImage: require('../assets/images/bg-01.jpg')}}>
				 <div className="flex-w size1 p-b-47">
					<div className="txt1 p-r-25">
						<span className="lnr lnr-map-marker"></span>
					</div>

					<div className="flex-col size2">
						<span className="txt1 p-b-20">
							Address
						</span>

						<span className="txt2">
							Mada Center 8th floor, 379 Hudson St, New York, NY 10018 US
						</span>
					</div>
				</div>

				<div className="dis-flex size1 p-b-47">
					<div className="txt1 p-r-25">
						<span className="lnr lnr-phone-handset"></span>
					</div>

					<div className="flex-col size2">
						<span className="txt1 p-b-20">
							Lets Talk
						</span>

						<span className="txt3">
							+1 800 1236879
						</span>
					</div>
				</div>

				<div className="dis-flex size1 p-b-47">
					<div className="txt1 p-r-25">
						<span className="lnr lnr-envelope"></span>
					</div>

					<div className="flex-col size2">
						<span className="txt1 p-b-20">
							General Support
						</span>

						<span className="txt3">
							contact@example.com
						</span>
					</div>
				</div> 
			</div>*/}
		</div>
	</div>

    );
}

export default Contact;