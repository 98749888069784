export default {
    firstNameLabel: "الاسم الشخصي",
    lastNameLabel: "اسم العائله",
    phoneLabel: "رفم الهاتف",
    emailLabel: "البريد الالكتروني",
    cityLabel: "البلد",
    firstNamePlaceHolder: "ماذا ينادوك؟",
    lastNamePlaceHolder: "ما اسم عائلتك؟",
    phonePlaceHolder: "الرجاء ادخال رقم الهاتف",
    emailPlaceHolder: "الرجاء ادخال البريد الالكتروني الخاص بك",
    cityPlaceHolder: "ما اسم بلدك؟",
    contactTitle: "اترك معلوماتك الشحصيه هنا",
    submitContactButtonText: "ارسال المعلومات",
    vehicleType: "نوع المركبه",
    vehicleOptions: [
        {name: "لا املك", val: "1"},
        {name: "سياره", val: "2"},
        {name: "دراجه هوائيه", val: "3"},
        {name: "دراجه ناريه", val: "4"},
    ],
    requiredField: "** حقل اجباري",
    invalidEmail: "الرجاء ادخال بريد الكتروني صحيح",
    invalidPhone: "الرجاء ادخال رقم هاتف صحيح - يبدأ ب 05",
    gender: "الجنس",
    genderOptions: [
        {name: "ذكر", val: "1"},
        {name: "انثى", val: "2"}
    ],
    changeLanguage: "שנה שפה",
    successMessage: "لقد تم تلقي الطلب بنجاح",
    errorMessage: "حدث خطأ اثناء الارسال, حاول مجددا"
};