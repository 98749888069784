export default {
    firstNameLabel: "שם פרטי",
    lastNameLabel: "שם משפחה",
    phoneLabel: "מספר טלפון",
    emailLabel: "דואר אלקטרוני",
    cityLabel: "עיר מגורים",
    firstNamePlaceHolder: "איך קוראים לך?",
    lastNamePlaceHolder: "מה שם המשפחה שלך?",
    phonePlaceHolder: "נא להזין את מספר הטלפון שלך",
    emailPlaceHolder: "נא להזין את הדואר האלקטרוני שלך",
    cityPlaceHolder: "מה עיר המגורים שלך?",
    contactTitle: "השאר את הפרטים שלך כאן",
    submitContactButtonText: "שליחת פרטים",
    vehicleType: "סוג רכב",
    vehicleOptions: [
        {name: "ללא", val: "1"},
        {name: "רכב פרטי", val: "2"},
        {name: "אופניים", val: "3"},
        {name: "קטנוע/ אופנוע", val: "4"},
    ],
    requiredField: "** שדה זה חובה",
    invalidEmail: "נא להזין דואר אלקטרוני תקין",
    invalidPhone: "נא להזין מספר טלפון תקין המתחיל ב 05",
    gender: "מין",
    genderOptions: [
        {name: "זכר", val: "1"},
        {name: "נקבה", val: "2"}
    ],
    changeLanguage: "تغيير اللغه",
    successMessage: "המייל נשלח בהצלחה",
    errorMessage: "אירעה שגיאה בעת שליחת המייל, נא לנסות שנית"
};