import React, { useState } from 'react';
import Home from './Home';
import Contact from './Contact';

function App() {


  return (
    //<Home />
      <Contact />
  );
}

export default App;
